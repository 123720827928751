import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions"
    }}><a parentName="h1" {...{
        "href": "#frequently-asked-questions",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Frequently Asked Questions`}</h1>
    <h2 {...{
      "id": "which-react-versions-are-supported"
    }}><a parentName="h2" {...{
        "href": "#which-react-versions-are-supported",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Which react versions are supported?`}</h2>
    <p>{`As defined in peer dependencies, Loadable Components supports React v16.3+.`}</p>
    <h2 {...{
      "id": "which-webpack-versions-are-supported"
    }}><a parentName="h2" {...{
        "href": "#which-webpack-versions-are-supported",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Which webpack versions are supported?`}</h2>
    <p>{`As defined in peer dependencies, Loadable Components supports webpack v4.6+.`}</p>
    <h2 {...{
      "id": "which-browsers-are-supported"
    }}><a parentName="h2" {...{
        "href": "#which-browsers-are-supported",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Which browsers are supported?`}</h2>
    <p>{`Loadable Components supports the same set of browsers as the current React version.`}</p>
    <ul>
      <li parentName="ul">{`v5.x (React v16.3+): IE11, IE 9+ (with Map + Set polyfills), all evergreen browsers`}</li>
    </ul>
    <p>{`Evergreen browsers include Chrome and Firefox (and derivatives) as they can be updated regardless of operating system version. Edge and Safari should both also work fine since all versions for the last several years support the relevant APIs.`}</p>
    <h2 {...{
      "id": "can-i-use-loadable-components-with-server-side-rendering-of-react-on-top-of-ruby-on-rails"
    }}><a parentName="h2" {...{
        "href": "#can-i-use-loadable-components-with-server-side-rendering-of-react-on-top-of-ruby-on-rails",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Can I use Loadable Components with server-side rendering of React on top of Ruby on Rails?`}</h2>
    <p>{`Yes. It works with `}<a parentName="p" {...{
        "href": "https://github.com/shakacode/react_on_rails"
      }}>{`React on Rails`}</a>{`, but it requires `}<a parentName="p" {...{
        "href": "https://www.shakacode.com/react-on-rails-pro/"
      }}>{`React on Rails Pro`}</a>{`. High traffic sites using React on Rails Pro with Loadable Components are `}<a parentName="p" {...{
        "href": "https://get.popmenu.com/"
      }}>{`popmenu.com`}</a>{` and `}<a parentName="p" {...{
        "href": "https://egghead.io/"
      }}>{`egghead.io`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      